<template>
  <div>
    <b-row>
      <b-col class="col-md-12">
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="24"
                        icon="BriefcaseIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      BDT {{ walletInfo.credit ? Number(walletInfo.credit).toLocaleString() : 0 }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Total Credit Limit
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-info"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      BDT {{ walletInfo.balance ? Number(walletInfo.balance).toLocaleString() : 0 }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Available Credit
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-warning"
                    >
                      <feather-icon
                        size="24"
                        icon="ClockIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      BDT {{ walletInfo.outstanding ? Number(walletInfo.outstanding).toLocaleString() : 0 }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Due Amount
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-danger"
                    >
                      <feather-icon
                        size="24"
                        icon="CalendarIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      10<sup>th</sup> Day
                      <small class="text-muted">(every month)</small>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Recharge Date
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div class="d-flex align-items-center justify-content-end mb-1">
      <b-button
        v-if="walletType === 'CREDIT'"
        variant="primary"
        class="mr-1"
        @click="openWalletForm('CREDIT')"
      >
        Request Credit
      </b-button>
      <b-button
        v-if="walletType === 'DEPOSIT'"
        variant="primary"
        @click="openWalletForm('DEPOSIT')"
      >
        Request Deposit
      </b-button>
    </div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row class="justify-content-end">
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search transaction id..."
              @input="debounceSearch(0, getWalletData)"
            />
            <v-select
              v-model="walletStatus"
              :options="['APPROVED', 'PENDING', 'REJECTED']"
              class="status-filter-select"
              placeholder="Select Status"
              @input="getWalletData()"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="flightBookingTable"
        :items="walletData"
        :fields="tableColumns"
        :busy="isTableLoading"
        primary-key="id"
        empty-text="No matching records found"
        responsive
        show-empty
        sticky-header="685px"
        class="position-relative"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :id="data.item.id"
            :class="data.item.status === 'PENDING' ? 'cursor-pointer' : ''"
            pill
            :variant="formatClassName(data.item.status)"
          >
            <template v-if="data.item.status === 'PENDING'">
              <feather-icon icon="Edit2Icon" />
              <b-popover
                :target="data.item.id"
                placement="right"
                triggers="click"
              >
                <template #title>
                  {{ data.item.transaction_id }}
                </template>
                <b-form @submit.prevent>
                  <b-form-group
                    label="Update status"
                    :label-for="data.item.id"
                  >
                    <v-select
                      v-model="status"
                      :options="['APPROVED','REJECTED']"
                      class="status-filter-select w-75"
                      placeholder="Select Status"
                    />
                  </b-form-group>
                </b-form>
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-end mt-1"
                  >
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      class="mr-1"
                      @click="hidePopoverBox(data.item.id)"
                    >
                      Close
                    </b-button>
                    <b-button
                      ref="confirmationApplyBtn"
                      variant="primary"
                      size="sm"
                      @click="updateStatus(data.item.id)"
                    >
                      <b-spinner
                        v-if="isBtnLoading"
                        small
                      />
                      Apply
                    </b-button>
                  </b-col>
                </b-row>
              </b-popover>
            </template>
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(proof_of_payment)="data">
          <b-link
            :href="data.item.proof_of_payment"
            target="_blank"
          >
            {{ data.item.proof_of_payment ? data.item.proof_of_payment.substr(data.item.proof_of_payment.length - 18, data.item.proof_of_payment.length)
              : 'N/A' }}
          </b-link>
        </template>
      </b-table>
      <!--pagination-->
      <TablePagination
        :limit="meta.limit"
        :list-data="walletData"
        :total="meta.total"
        @paginate="paginate"
      />
    </b-card>
    <CorporateWalletForm
      :request-type="formType"
      @getWalletList="getWalletData()"
    />
  </div>
</template>

<script>
import helper from '@/mixins/helper'
import api from '@/libs/api'
import TablePagination from '@/components/common/TablePagination.vue'
import CorporateWalletForm from '@/views/accounts/wallet/CorporateWalletForm.vue'

export default {
  name: 'CorporateWallet',
  components: {
    TablePagination,
    CorporateWalletForm,
  },
  mixins: [helper],
  props: {
    apiEndpoint: {
      type: String,
    },
    walletType: {
      type: String,
    },
  },
  data() {
    return {
      walletStatus: '',
      searchQuery: '',
      isTableLoading: false,
      isBtnLoading: false,
      status: '',
      walletData: [],
      companyId: this.$store.state.auth.accountInfo.b2e_account.company.company_id,
      walletInfo: {},
      formType: '',
      meta: {
        total: 0,
        limit: 10,
        offset: 0,
      },
      tableColumns: [
        { key: 'transaction_id', label: 'Transaction ID', thClass: 'md' },
        {
          key: 'created_at', label: 'Transaction Date', thClass: 'lg', formatter: value => this.formatDate(value, 'DD MMM, YYYY hh:mm A'),
        },
        { key: 'status', label: 'Status' },
        {
          key: 'amount', label: 'Amount', thClass: 'md', formatter: value => `BDT ${Number(value).toLocaleString()}`,
        },
        {
          key: 'due_credit', label: 'Due Credit', thClass: 'md', formatter: value => value || 'N/A',
        },
        {
          key: 'agent', label: 'Agent', thClass: 'md', formatter: value => (value ? value.name : 'N/A'),
        },
        {
          key: 'biz_user', label: 'Issuer', thClass: 'md', formatter: value => (value ? value.email : 'N/A'),
        },
        { key: 'proof_of_payment', label: 'Payment Slip', thClass: 'md' },
        {
          key: 'note', label: 'Note', thClass: 'lg', formatter: value => value || 'N/A',
        },
      ],
    }
  },
  computed: {
    filterQuery() {
      const search = this.searchQuery ? `&transaction_id=${this.searchQuery}` : ''
      const status = this.walletStatus ? `&status=${this.walletStatus}` : ''
      return `${search}${status}`
    },
  },
  mounted() {
    this.getWalletData()
  },
  methods: {
    getWalletData() {
      this.isTableLoading = true
      api.getData(`${this.apiEndpoint}?transaction_type=${this.walletType}&company=${this.companyId}&offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.walletData = response.data.result.results
          this.walletInfo = response.data.result.wallet
          this.meta = response.data.result.meta
        }
      }).catch().finally(() => {
        this.isTableLoading = false
      })
    },
    openWalletForm(type) {
      this.$root.$emit('bv::toggle::collapse', 'wallet-form')
      this.formType = type
    },
    hidePopoverBox(id) {
      this.$root.$emit('bv::hide::popover', id)
      this.status = ''
    },
    updateStatus(id) {
      this.isBtnLoading = true
      api.updateData(`/b2e_dashboard/wallet_transaction/update/${id}/`, true, {
        status: this.status,
      }).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Status has been updated successfully')
          this.hidePopoverBox(id)
          this.getWalletData()
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    paginate(page) {
      this.meta.offset = page
      this.getWalletData()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
